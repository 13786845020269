import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@qaroni-core/services/commons/error-handler/error-handler.service';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Injectable()
export class QaroniErrorsInterceptor implements HttpInterceptor {
  constructor(private errorHandlerService: ErrorHandlerService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let errorInfo: HttpErrorResponse = null;

    return next.handle(req).pipe(
      tap(
        () => {
          ///next
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            errorInfo = error;
          }
        }
      ),
      finalize(() => {
        if (errorInfo !== null) {
          if (this.validateUrl(errorInfo.url)) {
            this.errorHandlerService.setErrorHandler(errorInfo);
          }
        }
      })
    );
  }

  validateUrl(url: string): boolean {
    return (
      url.includes('/evaluations/') ||
      url.includes('/adjectives/') ||
      url.includes('/departments/') ||
      url.includes('/skills/') ||
      url.includes('/workstation/')
    );
  }
}
