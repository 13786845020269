import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppEnv, AppEnvWorkstations } from '@qaroni-core/config/app/app.config';
import { Observable } from 'rxjs';

@Injectable()
export class AppCodeInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let appCode = AppEnv.appCode;

    if (this.isWorkstationsRequest(request)) {
      appCode = AppEnvWorkstations.appCode;
    }

    if (!appCode) {
      return next.handle(request);
    }

    const headers = request.headers.set('App-Code', appCode);
    const req = request.clone({ headers });

    return next.handle(req);
  }

  private isWorkstationsRequest(request: HttpRequest<unknown>): boolean {
    return request.url.includes('workstation');
  }
}
