import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthStorageService } from '@qaroni-app/auth/services/o-auth-storage.service';
import { Observable } from 'rxjs';
import { OAuthWorkstationStorageService } from '../../workstations/services/o-auth/o-auth-storage.service';

@Injectable({
  providedIn: 'root',
})
export class OAuthInterceptor implements HttpInterceptor {
  constructor(
    private oAuthStorage: OAuthStorageService,
    private oauthWorkstationStorage: OAuthWorkstationStorageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = this.oAuthStorage.get()?.token;

    if (this.isWorkstationsRequest(request)) {
      token = this.oauthWorkstationStorage.get()?.token;
    }

    if (!token) {
      return next.handle(request);
    }

    const headers = request.headers.set('Authorization', `Bearer ${token}`);
    const req = request.clone({ headers });

    return next.handle(req);
  }

  private isWorkstationsRequest(request: HttpRequest<unknown>): boolean {
    return request.url.includes('workstation');
  }
}
