import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OauthGuardCanActivateFn } from '@qaroni-core/guards/o-auth/o-auth/o-auth.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'auth' },
  {
    path: 'auth',
    loadChildren: () =>
      import('@qaroni-app/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'welcome',
    canLoad: [OauthGuardCanActivateFn],
    loadChildren: () =>
      import('@qaroni-app/welcome/welcome.module').then(m => m.WelcomeModule),
  },
  {
    path: 'companies',
    canLoad: [OauthGuardCanActivateFn],
    loadChildren: () =>
      import('@qaroni-app/welcome/welcome.module').then(m => m.WelcomeModule),
  },
  {
    path: 'invitations',
    canLoad: [OauthGuardCanActivateFn],
    loadChildren: () =>
      import('@qaroni-app/invitations/invitations.module').then(
        m => m.InvitationsModule
      ),
  },
  {
    path: 'profile',
    canLoad: [OauthGuardCanActivateFn],
    loadChildren: () =>
      import('@qaroni-app/profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: 'version',
    loadChildren: () =>
      import('@qaroni-app/version/version.module').then(m => m.VersionModule),
  },
  {
    path: 'apps',
    loadChildren: () =>
      import('@qaroni-app/apps/apps.module').then(m => m.AppsModule),
  },
  {
    path: 'workstations',
    loadChildren: () =>
      import('@qaroni-app/workstations/workstations.module').then(
        m => m.WorkstationsModule
      ),
  },
  {
    path: '',
    canLoad: [OauthGuardCanActivateFn],
    loadChildren: () =>
      import('@qaroni-app/dashboard/dashboard.module').then(
        m => m.DashboardModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
